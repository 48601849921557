import { Box, Card, Heading, IconButton, Input, Text } from "@wix/design-system";
import { EMAIL, MAILTO } from "../utils/shared/constants";
import { useState } from "react";
import { Visible } from "@wix/wix-ui-icons-common";
import { getApiKey } from "../utils/api";

export const ApiCard = () => {
    const [apiKey, setApiKey] = useState('************');
    const [apiKeyFetched, setApiKeyFetched] = useState(false);

    const fetchApiKey = async () => {
        setApiKeyFetched(true);

        try {
            const apiKey = await getApiKey()
            setApiKey(apiKey);
        } catch (e) {
            console.error(e);
            setApiKeyFetched(false);
        }
    }

    return (
        <Card>
            <Card.Header title='API access'
                subtitle='Take advantage of our API to enable new business cases'
            />
            <Card.Content>
                <Text>We are opening our API access. You can create or redirect QR codes programatically.  Please send us an email to <a href={MAILTO}>{EMAIL}</a> so we can assist with implementation</Text>

                <Heading className="mt-3" size={'tiny'}>API Key:</Heading>
                <Box gap={2}>

                    <Input value={apiKey} readOnly={true} className="flex-1" />
                    <IconButton disabled={apiKeyFetched} onClick={fetchApiKey}>
                        <Visible />
                    </IconButton>
                </Box>
            </Card.Content>
        </Card>
    );
}